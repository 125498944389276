<template>
  <div id="details-page">
    <v-container>
      <v-layout row wrap>
        <v-flex>
          <h1>{{ pageTitle }}</h1>
          <p>{{ description }}</p>
        </v-flex>
        <v-layout column v-for="(s, i) in sections" :key="i">
          <v-flex xs12 sm6>
            <h2>{{ s.title }}</h2>
            <ul v-for="(b, j) in s.body" :key="j">
              <li>{{ b }}</li>
            </ul>
          </v-flex>

        </v-layout>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    sections:{
      type: Array
    }
  },
  data: () => ({})
}
</script>

<style scoped>

</style>