const ElectricalEngineering ={ 
  title: "Electrical Engineering Services",
  description: `Darceo has a broad range of technical capabilities in the electrical engineering fields. This expertise has
                been spread across many designs in many different market segments. The designs range from simple PCBA’s to 
                complex system designs. The skill capabilities are often combined across the team members to complete cross
                functional product capabilities.`,
  sections: [
    {
      title: "Electrical Design",
      body: [
        'Analog & Digital',
        'Power Supply Design',
        'Power Systems - Inverters/Converters',
        'RF Low to High Frequency (900MHz, 2.4GHz, 5GHz-6GHz, etc.)',
        'RF Communications - WiFi, Bluetooth, Ant, ISM, LoRa',
        'Embedded systems',
        'Magnetics',
        'DSP/Microcontrollers - TI, ARM, etc.',
        'FPGA/CPLD - Altera, Xilinx, etc.',
        'Bus and I/O Structures'
      ]
    },
    {
      title: "Electrical Design Process",
      body: [
        'Component Library Management',
        'Schematic/Design and Compenent Selection',
        'Electronic and RF Simulation',
        'PCBA Layout/Firmware Integration',
        'Systems Modeling',
        'Electrical/Mechanical Integration/Systems Design',
        'Design for Excellence (DFX) in Test, Manufacturability, Supply-Chain'
      ]
    },
    {
      title: "Toolset Knowledge",
      body: [
        'Altium Designer',
        'Cadence OrCAD Capture/ OrCAD PCB (Allegro)',
        'Mentor DX Designer/PADS',
        'Xilinx ISE and Altera Quartus',
        'RF99, LTSpice, EZNEC'
      ]
    },
    {
      title: "Technologies",
      body: [
        'Industrial/Home Automation and Controls',
        'IoT Sensors and Systems',
        'Medical Device (Diagnostic & Monitoring)',
        'Factory Automation and Capital Equipment',
        'Power Systems and Controls',
        'Avionics'
      ]
    }
  ]
 };

const MechanicalEngineering = { 
  title: "Mechanical Engineering",
  description: `Darceo’s lean and innovative approach to solving complex problems provides a total solution for highly technical and highly reliable products. With knowledge and access to industry leading toolsets, Darceo can provide the one stop solution to your complex problems.`,
  sections: [
    {
      title: "Capabilities",
      body: [
        'Design for Additive Manufacturing',
        '(Plastics and Metals)',
        'Stress/Heat Analysis',
        'Fluid Mechanics/Pneumatics Systems',
        'Plastics, Sheet metal, Milled, “Printed” Metal',
        'Stress/Finite Element Analysis (FEA)',
        'Hardware Tolerance GD&T',
        'Fastener Technologies',
        'Electromechanical Design & Integration',
        'Design Validation– Drop, Environmental, Finishes',
        'Color Material Finish',
        'Structures/Chassis/Hardware Design'
      ]
    },
    {
      title: "Technology Applications",
      body: [
        'Machine Design',
        'Plant Automation',
        'Nanofiber Electrospinning',
        'Medical Device Housings/Structures',
        'Drone Technologies',
        'Aerospace',
        'Aftermarket Automotive Accessories',
        'Electronic Enclosures',
        'Monitoring and Measurement Systems',
        'Test Equipment Design and Layout',
        '80/20 Chamber and Workbench Design',
        'Industrial Design/Product Definition',
        'Conceptual Designs and Renderings',
        'Prototyping and Fabrication',
        'Additive Manufacturing'
      ]
    },
    {
      title: "Toolset Knowledge",
      body: [
        'SolidWorks CAD',
        'SolidWorks Simulations',
        'NX/Unigraphics',
        'Creo',
        'AutoCAD'
      ]
    }
  ]
 };

const FirmwareSoftwareEngineering = {
  title: "Firmware Development",
  description: `Darceo has a combination of engineers that love to cross the rivers of hardware and software integration.  Electrical engineers and software engineers combine to integrate CPU architectures, peripherals, sensors, actuators, and firmware to make any embedded system function to the client’s requirements.`,
  sections: [
    {
      title: "Platforms & Peripherals",
      body: [
        'Multiple Chipsets:  Intel, NXP, STM, Atmel, Microchip, TI, etc.',
        'Peripherals: Ethernet, USB, SPI, I2C, UART, CAN, DMA, ADC, DAC, Timers, RTC, SDIO, etc.',
        'Wireless Communications: Wi-Fi, Bluetooth, ZigBee, ANT, Cellular, LoRa, ISM, etc.',
        'User Interfaces:  LCD Displays, Touch Screens, LED Displays, etc.',
        'Sensors: GPS, Biomedical, Environmental, Motion, Particle, Ultrasonic, etc.',
        'Actuators: Motors, Solenoids, Fans, Relays, Switches, etc.'
      ]
    },
    {
      title: "Firmware Functionality",
      body: [
        'Bare-Metal Development as well as Operating Systems: Linux, ThreadX, MQX, FreeRTOS etc.',
        'Network and Web Capable: TCP/IP, LwIP TCP/IP Stack, HTTP/HTTPS, Server/Client, etc.',
        'Internet of Things (IoT), Database, Cloud Applications and End-to-End Security',
        'Edge Computing',
        'Machine Learning',
        'Encrypted & Secure Transactions, Field Updates and Over-the-Air Updates',
        'Data Acquisition and Instrumentation',
        'Signal Processing (FIR & IIR Filters, FFT Frequency Analysis, etc.)',
        'Kalman Filters, Adaptive Filters, and Adaptive Control',
        'Encrypted & Signed Firmware Images for Microcontroller Secure Boot'
      ]
    },
    {
      title: "Toolset Knowledge",
      body: [
        'Multiple IDEs:  Keil, IAR, Visual Studio, Atmel Studio, MPLAB, Eclipse, etc. ',
        'Multiple Debuggers: Segger J-Link, STM ST-Link, Microchip MPLAB ICD, etc.',
        'Multiple Languages: C, C++, C#, Java, Assembly, etc.',
        'Over 100 Development Kits and Evaluation Boards to Accelerate Product Development'
      ]
    }
  ]
};

const IndustrialAutomation = {
  title: "Industrial Automation",
  description: `Darceo designs, manufactures, and integrates material handling and automation systems that will increase production, boost production line quality, and maximize return on investment.`,
  sections: [
    {
      title: "Capabilities",
      body: [
        'Servos',
        'Steppers',
        'Encoders',
        'Electronics',
        'Pneumatics and Hydraulics',
        'Positioning Systems and Actuators',
        'Conveyors, Packaging, and Handling',
        'Measuring Instruments',
        'Programmable Logic Controllers (PLC)',
        'Software and Firmware Development',
        'Quick Turn Concept to Production via SolidWorks'
      ]
    },
    {
      title: "Technology Applications",
      body: [
        'Electronics Manufacturing',
        'Robotics Automation',
        'Manufacturing Automation',
        'Modular Frame and Rail Systems',
        'Nanofiber Solutions',
        'Safety Automation and Protection',
        'Advanced Plasma Electrolytic Deposition',
        'Industrial Internet of Things (IIoT)'
      ]
    }
  ]
};

const TestEngineering = {
  title: "Test Development Services",
  description: `Darceo has 125 years combined test engineering experience from Fortune 50 companies to small contract manufacturing support. Test services include full factory strategy support to custom functional, systems, and reliability test systems. Darceo can help with design validation testing, regulatory testing, and complete factory test systems.`,
  sections: [
    {
      title: "Capabilities",
      body: [
        'Strategy Development',
        'BOM/Test Coverage Analysis',
        'Fault Spectrum',
        'DFT Analysis/ Reliability Testing',
        'Test Technology Road Mapping',
        'Turn-key System Development',
        'BIST/Embedded Self-Test',
        'Functional Test Platform Design',
        'Hardware, Fixturing, Software',
        'Run-in Development',
        'Halt, ESS, HASS Profile Development',
        'RF Test and Tuning'
      ]
    },
    {
      title: "Toolset Knowledge",
      body: [
        'LabVIEW, Test Stand',
        'Altium',
        'SolidWorks',
        'Custom Software Support - (e.g. Python, VSS, .Net, Visual Studio, Lab Windows CVI, HPVEE)',        
      ]
    }
  ]
};

const ProcessSupportLogistics = {
  title: "Process Support & Logistics",
  description: `Darceo is an established provider of professional services and cost-effective automation that answers real business needs. Through our experience in factory automation and control systems, Darceo brings a realistic perspective to the challenges posed by the size, complexity, and diversity towards increased factory throughput.`,
  sections: [
    {
      title: "Capabilities",
      body: [
        'Design for Manufacturability, Maintainability, Quality, Testability, and Supply Chain',
        'Factory Transfer Domestic and International',
        'New Product Introduction (NPI)',
        'Low Cost Country Supply Chain and Manufacturing',
        'Data Collection via Industrial Internet of Things (IIoT)',
        'Tooling and Automation',
        'Traceability',
        'Improvements through Process Control (Six-Sigma) and Factorial Analysis',
        'Assembly Line Optimization',
        'Accelerated Stress Screening',
        'Factory Quality Management Systems'
      ]
    },
    {
      title: "Toolset Knowledge",
      body: [
        'Solidworks / AutoCAD / Creo',
        'Simul8 – Increase Utilization of Assets',
        'Valor – Design and Life Cycle Analysis',
        'SiliconExpert – Product Life Cycle',
        'MS Project',
        'Product Lifecycle Management Tools',
        'Manufacturing Process and Traceability tools'
      ]
    }
  ]
}

const DisciplinesStore = {
  data: {
    ElectricalEngineering,
    MechanicalEngineering,
    FirmwareSoftwareEngineering,
    IndustrialAutomation,
    TestEngineering,
    ProcessSupportLogistics
  }
};

export default DisciplinesStore;